import React from 'react'
import { Form, Input } from 'antd'
import AceEditor from 'react-ace'
import 'brace/mode/json'
import 'brace/theme/xcode'

import CompileTemplateIcon from '../../../../../reusables/components/compile-template-icon'
import MemoryStore from '../../../../../utils/memory-store'

class GoogleDriveRoute extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      entry: props.entry.routeData
    }
  }

  onFieldChange(key, value) {
    this.props.entry.routeData[key] = value.trim()
  }

  render() {
    return (
      <>
        {this.props.routeType === '0' ? (
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>
            Parameters (JSON)
            <AceEditor
              readOnly={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              showPrintMargin={false}
              cursorStart={1}
              mode='json'
              theme='xcode'
              style={{ width: '100%', height: 100 }}
              name='aceEditor'
              value={this.state.entry.params}
              setOptions={{
                showLineNumbers: true,
                newLineMode: 'unix'
              }}
              onChange={(e) => {
                this.onFieldChange('params', e)
              }}
            />
          </Form.Item>
        ) : null}
        {this.props.routeType === '1' || this.props.routeType === '2' ? (
          <>
            {this.props.routeType === '1' || this.props.routeType === '2' ? (
              <Form.Item>
                <span style={{ color: 'red' }}>* </span>
                {'File Id '}
                <CompileTemplateIcon />
                <Input
                  name='file_id'
                  disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                  placeholder='Provide the Agilit-e File Id'
                  defaultValue={this.state.entry.fileId}
                  onChange={(e) => {
                    this.onFieldChange('fileId', e.target.value)
                  }}
                />
              </Form.Item>
            ) : null}
            {this.props.routeType === '1' ? (
              <Form.Item>
                {'Parent Folder Id (optional) '}
                <CompileTemplateIcon />
                <Input
                  name='parent_folder_id'
                  disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
                  placeholder='The Google Drive Folder Id'
                  defaultValue={this.state.entry.parentFolderId}
                  onChange={(e) => {
                    this.onFieldChange('parentFolderId', e.target.value)
                  }}
                />
              </Form.Item>
            ) : null}
          </>
        ) : null}
      </>
    )
  }
}

export default GoogleDriveRoute
