import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { combineReducers } from 'redux'
import { configureStore } from '@reduxjs/toolkit'
import { thunk } from 'redux-thunk'

import Config from './utils/config.json'
import { actions, verifyUser } from './core/core-actions'
import AgiliteUtils from 'agilite-utils'
import CoreAppContainer from './core/containers/core-app-container'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/react'

// CSS Files
import './index.css'

// MODULECONDITION: Custom Components Import
import core from './core/core-reducer'
import keywords from './keywords/keywords-reducer'
import tierstructures from './tier-structures/tier-structure-reducer'
import numbering from './numbering/numbering-reducer'
import connectors from './connectors/connectors-reducer'
import teamusers from './team-users/team-users-reducer'
import roles from './roles/roles-reducer'
import apikeys from './api-keys/api-keys-reducer'
import templates from './templates/template-reducer'
import datamappings from './data-mapping/data-mapping-reducer'
import bpm from './bpm/bpm-reducer'
import botbuilder from './bot-builder/bot-builder-reducer'
import gatewayadapters from './gateway-adapters/gateway-adapters-reducer'
import batchactions from './batch-actions/batch-actions-reducer'
import events from './events/events-reducer'
import batchlogging from './batch-logging/batch-logging-reducer'
import nodered from './node-red/node-red-reducer'
import solutions from './solutions/solutions-reducer'
import errorlogs from './error-logs/error-logs-reducer'

if (Config.sentry?.enabled) {
  const sentryConfig = Config.sentry.config
  sentryConfig.integrations = [Sentry.browserTracingIntegration(), Sentry.replayIntegration()]
  Sentry.init(sentryConfig)
}

// MODULECONDITION: Setup Reducers
export const store = configureStore({
  reducer: {
    core,
    keywords,
    tierstructures,
    numbering,
    connectors,
    teamusers,
    roles,
    apikeys,
    templates,
    datamappings,
    bpm,
    botbuilder,
    gatewayadapters,
    batchactions,
    events,
    batchlogging,
    nodered,
    solutions,
    errorlogs
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
  devTools: process.env.NODE_ENV === 'production' ? false : true,
  preloadedState: {}
})

// Check if there are url parameters
const params = AgiliteUtils.getQueryParams(window.location.href)
let initHomePage = true

if (params.a) {
  // We need to perform an action
  switch (params.a) {
    case 'r': // Registration Form
      initHomePage = false

      store.dispatch({
        type: 'INIT_REGISTER_FORM',
        key: params.key
      })

      break
    case 'vu': // Verify User
      if (params.key) {
        initHomePage = false

        verifyUser(params.key)
          .then((response) => {
            store.dispatch({
              type: actions.INIT_VERIFIED_SUCCESS_PAGE
            })
          })
          .catch((response) => {
            store.dispatch({
              type: actions.INIT_VERIFIED_FAILED_PAGE
            })
          })
      }

      break
    case 'pr': // Password Reset
      if (params.key) {
        initHomePage = false

        store.dispatch({
          type: actions.INIT_RESET_FORM,
          key: params.key
        })
      }

      break
    case 'atu': // Activate User Account
      if (params.key) {
        initHomePage = false

        store.dispatch({
          type: actions.INIT_ACTIVATE_FORM,
          key: params.key
        })
      }

      break
    default:
    // Do Nothing
  }
}

if (initHomePage) {
  store.dispatch({
    type: actions.INIT_HOME_PAGE
  })
}

ReactDOM.render(
  <Provider store={store}>
    <CoreAppContainer />
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
