import React, { useCallback, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { useDropzone } from 'react-dropzone'
import { Row, Col, Card, Spin, Table, message } from 'antd'

import configuration from '../../utils/config'
import MemoryStore from '../../utils/memory-store'
import Enums from '../../utils/enums'
import AgiliteUtils from 'agilite-utils'

const NodeRedFlowData = (props) => {
  const entry = props.entry

  const [state, setState] = useState({
    flow: [],
    credential: [],
    setting: [],
    session: [],
    showProgressFlow: false,
    showProgressCredential: false,
    showProgressSetting: false,
    showProgressSession: false
  })

  useEffect(() => {
    if (!entry.custom.isNewDoc) {
      setState((prevState) => ({
        ...prevState,
        showProgressFlow: true,
        showProgressCredential: true,
        showProgressSession: true,
        showProgressSetting: true
      }))

      MemoryStore.agilite.NodeRed.getFlowData(props.entry.data.key)
        .then((res) => {
          setState({
            flow: res.data.data.flows
              ? [{ _id: AgiliteUtils.uuidv1(), fileName: 'flows.json', data: res.data.data.flows, type: 'flow' }]
              : [],
            credential: res.data.data.credentials
              ? [
                  {
                    _id: AgiliteUtils.uuidv1(),
                    fileName: 'credentials.json',
                    data: res.data.data.credentials,
                    type: 'credential'
                  }
                ]
              : [],
            setting: res.data.data.settings
              ? [
                  {
                    _id: AgiliteUtils.uuidv1(),
                    fileName: 'settings.json',
                    data: res.data.data.settings,
                    type: 'setting'
                  }
                ]
              : [],
            session: res.data.data.sessions
              ? [
                  {
                    _id: AgiliteUtils.uuidv1(),
                    fileName: 'sessions.json',
                    data: res.data.data.sessions,
                    type: 'session'
                  }
                ]
              : [],
            showProgressFlow: false,
            showProgressCredential: false,
            showProgressSession: false,
            showProgressSetting: false
          })
        })
        .catch((err) => {
          setState((prevState) => ({
            ...prevState,
            showProgressFlow: false,
            showProgressCredential: false,
            showProgressSession: false,
            showProgressSetting: false
          }))

          if (err.response) {
            message.error(err.response.data.errorMessage)
          } else {
            message.error(Enums.MESSAGES.UNKNOWN_ERROR)
            console.log(err)
          }
        })
    }
  }, [])

  const Dropzone = ({ type, tmpThis }) => {
    const onDrop = useCallback((files) => {
      // eslint-disable-next-line
      const reader = new FileReader()
      let data = null

      reader.onabort = () => message.error('File reading was aborted')
      reader.onerror = () => message.error('File reading has failed')
      reader.onload = () => {
        try {
          data = JSON.parse(reader.result)

          switch (type) {
            case 'flow':
              setState((prevState) => ({ ...prevState, showProgressFlow: true }))
              entry.data.flows = data
              break
            case 'credential':
              setState((prevState) => ({ ...prevState, showProgressCredential: true }))
              entry.data.credentials = data
              break
            case 'setting':
              setState((prevState) => ({ ...prevState, showProgressSetting: true }))
              entry.data.settings = data
              break
            case 'session':
              setState((prevState) => ({ ...prevState, showProgressSession: true }))
              entry.data.sessions = data
              break
            default:
          }

          onAttachmentUploaded({ id: AgiliteUtils.uuidv1() }, type)
        } catch (error) {
          setState((prevState) => ({
            ...prevState,
            showProgressFlow: false,
            showProgressCredential: false,
            showProgressSession: false,
            showProgressSetting: false
          }))

          message.error('A valid JSON file is expected')
        }
      }
      if (files.length > 0) {
        reader.readAsBinaryString(files[0])
      }
      // eslint-disable-next-line
    }, [])
    const { getRootProps, getInputProps } = useDropzone({
      accept: '.json',
      multiple: false,
      maxSize: configuration.dropZoneConfig.import.maxFileSize,
      onDrop
    })

    return (
      <div {...getRootProps()} className='dropzone'>
        <input {...getInputProps()} />
        <p>Click here or drop JSON file for importing</p>
      </div>
    )
  }

  const onAttachmentUploaded = (res, type) => {
    if (res) {
      setTimeout(() => {
        const entry = {
          _id: res.id,
          fileName: `${type}.json`,
          key: res.id,
          type
        }

        switch (type) {
          case 'flow':
            setState((prevState) => ({
              ...prevState,
              showProgressFlow: false,
              flow: [entry]
            }))
            break
          case 'credential':
            setState((prevState) => ({
              ...prevState,
              showProgressCredential: false,
              credential: [entry]
            }))
            break
          case 'setting':
            setState((prevState) => ({
              ...prevState,
              showProgressSetting: false,
              setting: [entry]
            }))
            break
          case 'session':
            setState((prevState) => ({
              ...prevState,
              showProgressSession: false,
              session: [entry]
            }))
            break
          default:
        }
      }, 800)
    }
  }

  const columns = [
    {
      title: 'Attachments',
      dataIndex: 'fileName',
      key: 'key',
      render: (text, entry) => {
        return (
          <span>
            {/* eslint-disable-next-line */}
            <a
              onClick={() => {
                // eslint-disable-next-line
                const blob = new Blob([JSON.stringify(entry.data)])
                const url = URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = url
                a.download = entry.fileName
                a.style.display = 'none'
                document.body.appendChild(a)
                a.click()
              }}
            >
              {entry.fileName}
            </a>
          </span>
        )
      }
    },
    {
      title: '',
      key: 'actions',
      render: (text, entry) => {
        return (
          <span
            style={{
              color: 'red',
              fontSize: 16,
              cursor: 'pointer',
              padding: 3
            }}
            onClick={() => setState((prevState) => ({ ...prevState, [entry.type]: [] }))}
          >
            <FontAwesomeIcon icon={faTrash} />
          </span>
        )
      }
    }
  ]

  return (
    <Row justify='space-around'>
      <Col span={24}>
        <Row style={{ marginBottom: 10 }} gutter={10}>
          <Col span={12}>
            <Card title='Flows' type='inner'>
              <div style={{ marginTop: 10 }}>
                {state.showProgressFlow ? (
                  <Spin />
                ) : (
                  <div>
                    {state.flow.length === 0 ? (
                      <div style={{ zIndex: 1, position: 'relative' }}>
                        <Dropzone tmpThis={this} type='flow' />
                      </div>
                    ) : (
                      <Table
                        columns={columns}
                        dataSource={state.flow}
                        bordered
                        showHeader
                        pagination={false}
                        size='middle'
                        rowKey={(record) => record._id}
                      />
                    )}
                  </div>
                )}
              </div>
            </Card>
          </Col>
          <Col span={12}>
            <Card title='Credentials' type='inner'>
              <div style={{ marginTop: 10 }}>
                {state.showProgressCredential ? (
                  <Spin />
                ) : (
                  <div>
                    {state.credential.length === 0 ? (
                      <div style={{ zIndex: 1, position: 'relative' }}>
                        <Dropzone tmpThis={this} type='credential' />
                      </div>
                    ) : (
                      <Table
                        columns={columns}
                        dataSource={state.credential}
                        bordered
                        showHeader
                        pagination={false}
                        size='middle'
                        rowKey={(record) => record._id}
                      />
                    )}
                  </div>
                )}
              </div>
            </Card>
          </Col>
        </Row>
        <Row style={{ marginTop: 10 }} gutter={10}>
          <Col span={12}>
            <Card title='Settings' type='inner'>
              <div style={{ marginTop: 10 }}>
                {state.showProgressSetting ? (
                  <Spin />
                ) : (
                  <div>
                    {state.setting.length === 0 ? (
                      <div style={{ zIndex: 1, position: 'relative' }}>
                        <Dropzone tmpThis={this} type='setting' />
                      </div>
                    ) : (
                      <Table
                        columns={columns}
                        dataSource={state.setting}
                        bordered
                        showHeader
                        pagination={false}
                        size='middle'
                        rowKey={(record) => record._id}
                      />
                    )}
                  </div>
                )}
              </div>
            </Card>
          </Col>
          <Col span={12}>
            <Card title='Sessions' type='inner'>
              <div style={{ marginTop: 10 }}>
                {state.showProgressSession ? (
                  <Spin />
                ) : (
                  <div>
                    {state.session.length === 0 ? (
                      <div style={{ zIndex: 1, position: 'relative' }}>
                        <Dropzone tmpThis={this} type='session' />
                      </div>
                    ) : (
                      <Table
                        columns={columns}
                        dataSource={state.session}
                        bordered
                        showHeader
                        pagination={false}
                        size='middle'
                        rowKey={(record) => record._id}
                      />
                    )}
                  </div>
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default NodeRedFlowData
