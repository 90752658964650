import AgiliteUtils from 'agilite-utils'

import MemoryStore from '../utils/memory-store'
import { cleanupObject, isDuplicateEntry, updateArrayDataFromSave } from '../utils/utilities'
import { postData } from '../core/core-utils'
import { formatRecord } from './data-mapping-utils'
import Enums from '../utils/enums'

/*
  ENUMS
*/
export const actions = {
  RESET_STATE: 'RESET_STATE_DATAMAPPINGS',
  CREATE_PROFILE: 'CREATE_PROFILE_DATAMAPPINGS',
  SAVE_PROFILE: 'SAVE_PROFILE_DATAMAPPINGS',
  CANCEL_PROFILE: 'CANCEL_PROFILE_DATAMAPPINGS',
  EDIT_PROFILE: 'EDIT_PROFILE_DATAMAPPINGS',
  DELETE_PROFILE: 'DELETE_PROFILE_DATAMAPPINGS',
  FETCH_ALL_DATA: 'FETCH_ALL_DATA_DATAMAPPINGS',
  SET_SEARCH_FILTER: 'SET_SEARCH_FILTER_DATAMAPPINGS',
  SET_MAPPING_SEARCH_FILTER: 'SET_MAPPING_SEARCH_FILTER_DATAMAPPINGS',
  SET_MULTI_ENTRY_SEARCH_FILTER: 'SET_MULTI_ENTRY_SEARCH_FILTER_DATAMAPPINGS',
  UPDATE_DATA: 'UPDATE_DATA_DATAMAPPINGS'
}

/*
  DISPATCH FUNCTIONS
*/
export const setSearchFilter = (value) => {
  return {
    type: actions.SET_SEARCH_FILTER,
    value
  }
}

export const setMappingSearchFilter = (value) => {
  return {
    type: actions.SET_MAPPING_SEARCH_FILTER,
    value
  }
}

export const setMultiEntrySearchFilter = (value) => {
  return {
    type: actions.SET_MULTI_ENTRY_SEARCH_FILTER,
    value
  }
}

export const updateData = (data) => {
  return {
    type: actions.UPDATE_DATA,
    data
  }
}

export const saveProfile = (appId, recordId, state, callback) => {
  return async (dispatch) => {
    const entry = MemoryStore.activeEntries[recordId]
    const headers = {}

    let result = true
    let reqType = ''
    let tempArray = state.data.concat()
    let errMsg = null

    // Cleanup and Trim
    cleanupObject(entry.data)
    await AgiliteUtils.trimObjectValues(entry.data)

    // Duplicate Check
    result = isDuplicateEntry(entry, 'key', tempArray, true)

    if (!result) {
      errMsg = `Profile Key '${entry.data.key}' is already being used`
    } else if (!entry.data.key) {
      errMsg = 'Please provide a Profile Key'
    } else if (!entry.data.name) {
      errMsg = 'Please provide a Profile Name'
    } else if (!entry.data.sourceType) {
      errMsg = 'Please Provide a Source Type'
    } else if (!entry.data.destinationType) {
      errMsg = 'Please Provide a Destination Type'
    } else if (entry.data.sourceType === '2' && !entry.data.srcExcel.fileId) {
      errMsg = 'Please provide an Agilit-e File ID for the Src Excel File'
    } else if (
      entry.data.destinationType === '2' &&
      entry.data.desExcel.desType === '1' &&
      !entry.data.desExcel.fileName
    ) {
      errMsg = 'Please provide a File Name for the destination Excel File'
    }

    if (errMsg) return callback(errMsg)

    // Post Data to Agilit-e
    tempArray = formatRecord(entry, tempArray)
    reqType = entry.custom.action === 'create' ? 'post' : 'put'

    // Create Headers Object
    headers['record-id'] = recordId

    postData(appId, reqType, entry, headers)
      .then((response) => {
        callback(null)

        entry.custom.action = ''
        entry.custom.isSavedDoc = true
        entry.key = response.data._id
        entry._id = response.data._id
        entry.data.isActiveDescription = response.data.data.isActive ? 'Yes' : 'No'

        // Dispatch New Array
        tempArray = updateArrayDataFromSave(entry, tempArray)
        dispatch({ type: actions.UPDATE_DATA, data: tempArray })
      })
      .catch((error) => {
        console.log(error)

        if (error.response) {
          callback(error.response.data.errorMessage)
        } else {
          callback(Enums.MESSAGES.UNKNOWN_ERROR)
        }
      })
  }
}
