import AgiliteUtils from 'agilite-utils'

import MemoryStore from '../utils/memory-store'
import { cleanupObject, isDuplicateEntry, updateArrayDataFromSave, formatAliasValue } from '../utils/utilities'
import { postData } from '../core/core-utils'
import { formatRecord, cleanupData } from './bpm-utils'
import Enums from '../utils/enums'

/*
  ENUMS
*/
export const actions = {
  RESET_STATE: 'RESET_STATE_BPM',
  CREATE_PROFILE: 'CREATE_PROFILE_BPM',
  SAVE_PROFILE: 'SAVE_PROFILE_BPM',
  CANCEL_PROFILE: 'CANCEL_PROFILE_BPM',
  EDIT_PROFILE: 'EDIT_PROFILE_BPM',
  DELETE_PROFILE: 'DELETE_PROFILE_BPM',
  FETCH_ALL_DATA: 'FETCH_ALL_DATA_BPM',
  SET_SEARCH_FILTER: 'SET_SEARCH_FILTER_BPM',
  SET_PROCESS_STEP_SEARCH_FILTER: 'SET_PROCESS_STEP_SEARCH_FILTER_BPM',
  SET_STEP_OPTION_SEARCH_FILTER: 'SET_STEP_OPTION_SEARCH_FILTER_BPM',
  UPDATE_DATA: 'UPDATE_DATA_BPM'
}

/*
  DISPATCH FUNCTIONS
*/
export const setSearchFilter = (value) => {
  return {
    type: actions.SET_SEARCH_FILTER,
    value
  }
}

export const setProcessStepSearchFilter = (value) => {
  return {
    type: actions.SET_PROCESS_STEP_SEARCH_FILTER,
    value
  }
}

export const setStepOptionSearchFilter = (value) => {
  return {
    type: actions.SET_STEP_OPTION_SEARCH_FILTER,
    value
  }
}

export const updateData = (data) => {
  return {
    type: actions.UPDATE_DATA,
    data
  }
}

export const saveProfile = (appId, recordId, state, updateStubs, callback) => {
  return async (dispatch) => {
    const entry = MemoryStore.activeEntries[recordId]
    const headers = {}
    let result = true
    let reqType = ''
    let tempArray = state.data.concat()
    let errMsg = null
    let tmpCounter = 0

    // Cleanup and Trim
    cleanupObject(entry.data)
    await AgiliteUtils.trimObjectValues(entry.data)
    entry.data.key = formatAliasValue(entry.data.key)

    // Validation & Cleanup
    if (!entry.data.key) {
      errMsg = 'Please provide a valid Profile Key'
      return callback(errMsg)
    }

    if (!entry.data.name) {
      errMsg = 'Please provide a valid Profile Name'
      return callback(errMsg)
    }

    if (entry.data.lockedExpiry === '') {
      errMsg = 'Please provide a Locked Expiry'
      return callback(errMsg)
    }

    entry.data.processSteps.map((processStep) => {
      // Check if there is more than 1 First Step
      if (processStep.stepType === 'First Step') tmpCounter++
      return null
    })

    if (tmpCounter > 1) {
      errMsg = "Only 1 Step Type of 'First Step' is allowed"
      return callback(errMsg)
    } else if (tmpCounter === 0) {
      errMsg = "Please provide a Process Step with a Step Type of 'First Step'"
      return callback(errMsg)
    }

    // Check that there is at least 1 Completed Step
    tmpCounter = 0
    entry.data.processSteps.map((processStep) => {
      if (processStep.stepType === 'Completed Step') tmpCounter++
      return null
    })

    if (tmpCounter === 0) {
      errMsg = 'Please create at least 1 Completed Process Step'
      return callback(errMsg)
    }

    // Duplicate Check
    result = isDuplicateEntry(entry, 'key', tempArray, true)

    if (!result) {
      errMsg = `Profile Key '${entry.data.key}' is already being used`
      return callback(errMsg)
    }

    // Cleanup root keywords
    entry.data.keywords = cleanupData(entry.data.keywords)

    // Cleanup process step and step option keywords
    entry.data.processSteps.map((processStep) => {
      processStep.keywords = cleanupData(processStep.keywords)

      processStep.stepOptions.map((stepOption) => {
        stepOption.keywords = cleanupData(stepOption.keywords)
        return null
      })
      return null
    })

    // Post Data to Agilit-e
    tempArray = formatRecord(entry, tempArray)
    reqType = entry.custom.action === 'create' ? 'post' : 'put'

    // Create Headers Object
    headers['record-id'] = recordId
    headers['update-stubs'] = updateStubs

    postData(appId, reqType, entry, headers)
      .then((response) => {
        callback(null)

        entry.custom.action = ''
        entry.custom.isSavedDoc = true
        entry.key = response.data._id
        entry._id = response.data._id
        entry.data.isActiveDescription = response.data.data.isActive ? 'Yes' : 'No'

        if (response.data.data.numberingId) {
          entry.data.numberingId = response.data.data.numberingId
        }

        // Dispatch New Array
        tempArray = updateArrayDataFromSave(entry, tempArray)
        dispatch({ type: actions.UPDATE_DATA, data: tempArray })
      })
      .catch((error) => {
        console.log(error)

        if (error.response) {
          callback(error.response.data.errorMessage)
        } else {
          callback(Enums.MESSAGES.UNKNOWN_ERROR)
        }
      })
  }
}
