import { connect } from 'react-redux'
import { setSearchFilter, setErrorLogsData } from '../error-logs-actions'
import { filterViewData } from '../error-logs-utils'
import ListViewContainer from '../../reusables/containers/list-view-container'
import Enums from '../../utils/enums'

const mapStateToProps = (state) => {
  if (!state[state.core.tabObject.activeApp]) {
    return {}
  }

  if (state.core.tabObject.activeApp !== Enums.APP_IDS.ERROR_LOGS) {
    return {}
  }

  const appId = state.core.tabObject.activeApp
  const data = state[appId].data
  const viewData = filterViewData(data, state[appId].searchFilter)
  const createNewHidden = true
  const paginationEnabled = true

  return {
    viewData,
    createNewHidden,
    paginationEnabled
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setSearchFilter: (value) => {
      dispatch(setSearchFilter(value))
    },
    setErrorLogsData: (data) => {
      dispatch(setErrorLogsData(data))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ListViewContainer)
