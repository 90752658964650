import React from 'react'
import Globals from '../../../utils/globals'
import { Col, Row, Card, Form, Input } from 'antd'

const FormItem = Form.Item

const AdminGeneral = () => (
  <div>
    <Row type='flex' justify='center'>
      <Col xs={24} sm={16} md={14} lg={10}>
        <Card title='Administration - About' style={{ marginTop: 20, textAlign: 'left' }} type='inner'>
          <Form>
            <FormItem>
              Agilit-e Version:
              <br />
              <Input type='text' readOnly defaultValue={process.env.REACT_APP_VERSION} style={{ width: '50%' }} />
            </FormItem>
            <FormItem>
              Release Date:
              <br />
              <Input type='text' readOnly defaultValue={process.env.RELEASE_DATE} style={{ width: '50%' }} />
            </FormItem>
          </Form>
        </Card>
      </Col>
    </Row>
  </div>
)

export default AdminGeneral
