import AgiliteUtils from 'agilite-utils'

import MemoryStore from '../utils/memory-store'
import { cleanupObject, isDuplicateEntry, updateArrayDataFromSave } from '../utils/utilities'
import { postData } from '../core/core-utils'
import { formatRecord } from './gateway-adapters-utils'
import Enums from '../utils/enums'

/*
  ENUMS
*/
export const actions = {
  RESET_STATE: 'RESET_STATE_GATEWAYADAPTERS',
  CREATE_PROFILE: 'CREATE_PROFILE_GATEWAYADAPTERS',
  SAVE_PROFILE: 'SAVE_PROFILE_GATEWAYADAPTERS',
  CANCEL_PROFILE: 'CANCEL_PROFILE_GATEWAYADAPTERS',
  EDIT_PROFILE: 'EDIT_PROFILE_GATEWAYADAPTERS',
  DELETE_PROFILE: 'DELETE_PROFILE_GATEWAYADAPTERS',
  FETCH_ALL_DATA: 'FETCH_ALL_DATA_GATEWAYADAPTERS',
  SET_SEARCH_FILTER: 'SET_SEARCH_FILTER_GATEWAYADAPTERS',
  SET_MAPPINGS_SEARCH_FILTER: 'SET_MAPPINGS_SEARCH_FILTER_GATEWAYADAPTERS',
  UPDATE_DATA: 'UPDATE_DATA_GATEWAYADAPTERS'
}

/*
  DISPATCH FUNCTIONS
*/
export const setSearchFilter = (value) => {
  return {
    type: actions.SET_SEARCH_FILTER,
    value
  }
}

export const updateData = (data) => {
  return {
    type: actions.UPDATE_DATA,
    data
  }
}

export const saveProfile = (appId, recordId, state, publish, callback) => {
  return async (dispatch) => {
    const entry = MemoryStore.activeEntries[recordId]
    const headers = {}

    let result = true
    let reqType = ''
    let tempArray = state.data.concat()
    let errMsg = null

    // Cleanup and Trim
    cleanupObject(entry.data)
    await AgiliteUtils.trimObjectValues(entry.data)

    // Then check for Duplicates
    result = isDuplicateEntry(entry, 'url', tempArray, true)

    if (!result) {
      errMsg = `Adapter URL '${entry.data.url}' is already being used`
    } else if (entry.data.mappings.length === 0) {
      errMsg = 'Please provide at least 1 Gateway Mapping Profile'
    } else if (!entry.data.name) {
      errMsg = 'Please provide a Profile Name'
    } else if (!entry.data.apiKey) {
      errMsg = 'Please provide an API Key'
    } else if (!entry.data.url) {
      errMsg = 'Please provide an Adapter URL'
    } else if (entry.data.url.endsWith('/')) {
      errMsg = "Gateway Adapter URL can not end with '/'"
    }

    if (errMsg) return callback(errMsg)
    _cleanupData(entry.data.mappings)

    // Post Data to Agilit-e
    tempArray = formatRecord(entry, tempArray)
    reqType = entry.custom.action === 'create' ? 'post' : 'put'

    // Create headers Object
    headers['record-id'] = recordId
    headers.publish = publish

    postData(appId, reqType, entry, headers)
      .then((response) => {
        callback(null)

        entry.custom.action = ''
        entry.custom.isSavedDoc = true
        entry.key = response.data._id
        entry._id = response.data._id
        response.data.data.isActiveDescription = response.data.data.isActive ? 'Yes' : 'No'

        // Dispatch New Array
        tempArray = updateArrayDataFromSave(entry, tempArray)
        dispatch({ type: actions.UPDATE_DATA, data: tempArray })
      })
      .catch((error) => {
        console.log(error)

        if (error.response) {
          callback(error.response.data.errorMessage)
        } else {
          callback(Enums.MESSAGES.UNKNOWN_ERROR)
        }
      })
  }
}

// PRIVATE FUNCTIONS
const _cleanupData = (dataArray) => {
  let entry

  // First Strip empty values from array
  for (let x = 0, y = dataArray.length; x < y; x++) {
    entry = dataArray[x]

    // Delete isNewEntry and isActiveDescription
    delete entry.isNewEntry
    delete entry.isActiveDescription
  }

  return dataArray
}
