import React, { useState, useEffect } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import ReCAPTCHA from 'react-google-recaptcha'
import Config from '../../utils/config'

import MemoryStore from '../../utils/memory-store'
import Enums from '../../utils/enums'
import { Form, Input, Button, Row, Col, Spin, Card, Alert, message } from 'antd'
import { MailOutlined, LockOutlined } from '@ant-design/icons'
import AgiliteHeader from '../../reusables/components/agilite-header'

const FormItem = Form.Item

const CoreSignIn = ({
  signInUser,
  signInClick,
  signInClicked,
  initForgotPasswordForm,
  initRegisterForm,
  verifyRecaptchaToken,
  verifyRecaptchaTokenv2
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [alertMessage, setAlertMessage] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [lowScore, setLowScore] = useState(null)
  const [signInDisabled, setSignInDisabled] = useState(true)

  const entry = MemoryStore.registerForm
  const reCaptchaEnabled = Config.reCaptcha?.enabled || false

  const onChange = (key, value) => {
    switch (key) {
      case 'inputEmail':
        entry.email = value
        break
      case 'inputPassword':
        entry.password = value
        break
      default:
    }
  }

  useEffect(() => {
    if (reCaptchaEnabled) {
      if (!executeRecaptcha) return
      _executeRecaptcha()
    } else {
      setSignInDisabled(false)
    }
    // eslint-disable-next-line
  }, [executeRecaptcha])

  const _executeRecaptcha = async () => {
    let result = null

    try {
      result = await executeRecaptcha('sign_in')

      verifyRecaptchaToken(result, (res, err) => {
        if (err) {
          setLowScore(true)

          if (err.response) {
            message.error(err.response.data.errorMessage)
          } else {
            message.error(Enums.MESSAGES.UNKNOWN_ERROR)
          }
          return
        }

        if (res.data.success) {
          if (res.data.score < 0.5) {
            setLowScore(true)
          } else {
            setLowScore(false)
            setSignInDisabled(false)
          }
        } else {
          setLowScore(true)
        }
      })
    } catch (e) {
      setLowScore(true)
      console.log(e)
    }
  }

  const verifyRecaptcha = (token) => {
    verifyRecaptchaTokenv2(token, (res, err) => {
      if (err) {
        if (err.response) {
          message.error(err.response.data.errorMessage)
        } else {
          message.error(Enums.MESSAGES.UNKNOWN_ERROR)
        }
        return
      }

      if (res.data.success) {
        setSignInDisabled(false)
      } else {
        message.error('reCaptcha Verification Failed')
      }
    })
  }

  return (
    <div>
      <Row type='flex' justify='center'>
        <Col xs={23} sm={23} md={16} lg={12} xl={10} xxl={8}>
          <div>
            <center>
              <AgiliteHeader header={Enums.VALUES_STRINGS.AGILITE_WELCOME} />
            </center>
          </div>
          <Card title='Sign in below to access the Portal' type='inner'>
            <Form
              className='login-form'
              onFinish={() => {
                signInClick()
                signInUser((err, message) => {
                  if (err) {
                    setAlertMessage(message)
                    setShowAlert(true)
                  } else {
                    setAlertMessage('')
                    setShowAlert(false)
                  }
                })
              }}
            >
              <FormItem>
                <Input
                  name='email'
                  disabled={signInClicked}
                  prefix={<MailOutlined style={{ color: '#7F7F7F' }} />}
                  type='email'
                  placeholder='Email'
                  defaultValue={entry.email}
                  required
                  onChange={(e) => {
                    onChange('inputEmail', e.target.value)
                  }}
                />
              </FormItem>
              <FormItem>
                <Input
                  name='password'
                  disabled={signInClicked}
                  prefix={<LockOutlined style={{ color: '#7F7F7F' }} />}
                  required
                  placeholder='Password'
                  type='password'
                  defaultValue={entry.password}
                  onChange={(e) => {
                    onChange('inputPassword', e.target.value)
                  }}
                />
              </FormItem>
              <Row type='flex' justify='center'>
                <Col>
                  <FormItem>
                    <Button
                      disabled={signInClicked || signInDisabled}
                      style={{
                        backgroundColor: '#67AD5B',
                        color: 'white',
                        width: 'auto',
                        marginRight: 10
                      }}
                      htmlType='submit'
                    >
                      Sign In
                    </Button>
                    <Button
                      disabled={signInClicked}
                      style={{
                        backgroundColor: '#EB5181',
                        color: 'white',
                        width: 'auto',
                        marginRight: 10
                      }}
                      onClick={(e) => {
                        e.preventDefault()
                        initForgotPasswordForm()
                      }}
                    >
                      Forgot Password
                    </Button>
                    <Button
                      disabled={signInClicked}
                      style={{
                        backgroundColor: '#54B9D1',
                        color: 'white',
                        width: 'auto'
                      }}
                      onClick={(e) => {
                        e.preventDefault()
                        initRegisterForm()
                      }}
                    >
                      Register
                    </Button>
                  </FormItem>
                  {lowScore ? (
                    <div style={{ marginTop: 10 }}>
                      <ReCAPTCHA sitekey={Config.reCaptcha.v2_siteKey} onChange={verifyRecaptcha} />
                    </div>
                  ) : null}
                  {showAlert ? (
                    <Alert
                      style={{ marginTop: 10, marginBottom: 10 }}
                      message={alertMessage}
                      closable={false}
                      type='error'
                    />
                  ) : null}
                  {signInClicked === true ? (
                    <div className='spin'>
                      <Spin size='large' />
                    </div>
                  ) : null}
                </Col>
              </Row>
            </Form>
          </Card>
          {reCaptchaEnabled ? (
            <Card style={{ textAlign: 'center', marginTop: 10 }}>
              This site is protected by reCAPTCHA and the Google
              <a href='https://policies.google.com/privacy' rel='noopener noreferrer' target='_blank'>
                {' '}
                Privacy Policy
              </a>{' '}
              and
              <a href='https://policies.google.com/terms' rel='noopener noreferrer' target='_blank'>
                {' '}
                Terms of Service
              </a>{' '}
              apply.
            </Card>
          ) : null}
        </Col>
      </Row>
    </div>
  )
}

export default CoreSignIn
