import React, { useState } from 'react'
import AceEditor from 'react-ace'
import TypeDetect from 'agilite-utils/dist/type-detect'
import EnumsTypeDetect from 'agilite-utils/dist/enums-type-detect'
import Moment from 'moment'
import { Row, Col, Form, Input, Button, message } from 'antd'

import { handleExport } from '../error-logs-utils'

import 'brace/mode/json'
import 'brace/theme/github'

const ErrorLogsForm = (props) => {
  const { record, categoryTitle = 'Module', subCategoryTitle = 'Endpoint', statusTitle = 'Status Code' } = props

  const [loading, setLoading] = useState(false)

  return (
    <Form>
      <Row justify='space-between'>
        <Col span={10}>
          <Form.Item>
            Date
            <Input defaultValue={Moment(record.createdAt).format('YYYY-MM-DD HH:mm')} readOnly />
          </Form.Item>
          <Form.Item>
            {categoryTitle}
            <Input defaultValue={record.data.moduleName} readOnly />
          </Form.Item>
          <Form.Item>
            {subCategoryTitle}
            <Input defaultValue={record.data.uri} readOnly />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item>
            {statusTitle}
            <Input defaultValue={record.data.responseCode} readOnly />
          </Form.Item>
          {record.data.method ? (
            <Form.Item>
              Method
              <Input defaultValue={record.data.method} readOnly />
            </Form.Item>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item>
            {TypeDetect(record.data.response) === EnumsTypeDetect.OBJECT ? (
              <>
                Response
                <AceEditor
                  readOnly
                  showPrintMargin={false}
                  cursorStart={1}
                  mode='json'
                  theme='github'
                  style={{ width: '100%', height: 200 }}
                  name='aceEditor'
                  value={JSON.stringify(record.data.response, null, '\t')}
                  editorProps={{
                    $blockScrolling: Infinity
                  }}
                />
              </>
            ) : (
              <>
                {props.bodyTitle ? 'Message' : 'Error Message'}
                <Input readOnly value={record.data.response} />
              </>
            )}
          </Form.Item>
          {record.data.headers ? (
            <Form.Item>
              Request Headers
              <AceEditor
                readOnly
                showPrintMargin={false}
                cursorStart={1}
                mode='json'
                theme='github'
                style={{ width: '100%', height: 200 }}
                name='aceEditor'
                value={JSON.stringify(record.data.headers, null, '\t')}
                editorProps={{
                  $blockScrolling: Infinity
                }}
              />
            </Form.Item>
          ) : null}
          <Form.Item>
            {props.bodyTitle ? props.bodyTitle : 'Request Body'}
            <AceEditor
              readOnly
              showPrintMargin={false}
              cursorStart={1}
              mode='json'
              theme='github'
              style={{ width: '100%', height: 200 }}
              name='aceEditor'
              value={JSON.stringify(record.data.body, null, '\t')}
              editorProps={{
                $blockScrolling: Infinity
              }}
            />
          </Form.Item>
          {record.data.body ? (
            <Button
              cypessid='export_request_body'
              onClick={async () => {
                try {
                  setLoading(true)
                  await handleExport(record.data.body)
                  setLoading(false)
                } catch (e) {
                  message.error(e)
                  setLoading(false)
                }
              }}
              loading={loading}
            >
              Export {props.bodyTitle ? props.bodyTitle : 'Request Body'}
            </Button>
          ) : null}
        </Col>
      </Row>
    </Form>
  )
}

export default ErrorLogsForm
