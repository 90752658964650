import AgiliteUtils from 'agilite-utils'

import uniqueId from 'lodash/uniqueId'
import MemoryStore from '../utils/memory-store'
import { cleanupObject, isDuplicateEntry, updateArrayDataFromSave } from '../utils/utilities'
import { postData } from '../core/core-utils'
import { formatRecord, cleanupData } from './tier-structure-utils'
import Enums from '../utils/enums'

/*
  ENUMS
*/
export const actions = {
  RESET_STATE: 'RESET_STATE_TIERSTRUCTURES',
  CREATE_PROFILE: 'CREATE_PROFILE_TIERSTRUCTURES',
  SAVE_PROFILE: 'SAVE_PROFILE_TIERSTRUCTURES',
  CANCEL_PROFILE: 'CANCEL_PROFILE_TIERSTRUCTURES',
  EDIT_PROFILE: 'EDIT_PROFILE_TIERSTRUCTURES',
  DELETE_PROFILE: 'DELETE_PROFILE_TIERSTRUCTURES',
  FETCH_ALL_DATA: 'FETCH_ALL_DATA_TIERSTRUCTURES',
  SET_SEARCH_FILTER: 'SET_SEARCH_FILTER_TIERSTRUCTURES',
  UPDATE_DATA: 'UPDATE_DATA_TIERSTRUCTURES'
}

/*
  DISPATCH FUNCTIONS
*/
export const setSearchFilter = (value) => {
  return {
    type: actions.SET_SEARCH_FILTER,
    value
  }
}

export const updateData = (data) => {
  return {
    type: actions.UPDATE_DATA,
    data
  }
}

export const saveProfile = (appId, recordId, state, callback) => {
  return async (dispatch) => {
    const entry = MemoryStore.activeEntries[recordId]
    const headers = {}

    let result = true
    let reqType = ''
    let tempArray = state.data.concat()
    let tempValueArray = []
    let tmpEntry = null
    let tmpArray = null
    let errorMsg = null

    // Cleanup and Trim
    cleanupObject(entry.data)
    await AgiliteUtils.trimObjectValues(entry.data)

    // Validation
    if (!entry.data.key || entry.data.key === '') {
      errorMsg = 'Please provide a Tier Key'
      return callback(errorMsg)
    }

    // Trim Key
    entry.data.key = entry.data.key.toLowerCase()

    // Duplicate Check
    result = isDuplicateEntry(entry, 'key', tempArray, true)

    if (!result) {
      errorMsg = `Tier Key '${entry.data.key}' is already being used`
      return callback(errorMsg)
    }

    // Check that at least one value exists
    tempValueArray = cleanupData(entry.data.values)

    if (entry.data.values) {
      for (const x in entry.data.values) {
        tmpEntry = entry.data.values[x]
        // eslint-disable-next-line
        tmpArray = entry.data.values.filter(
          (t) =>
            t.label.toLowerCase() === tmpEntry.label.toLowerCase() &&
            t.value.toLowerCase() === tmpEntry.value.toLowerCase()
        )

        if (tmpArray.length > 1) {
          errorMsg = `Duplicate Label/Value found for - (${tmpEntry.label}/${tmpEntry.value})`
          return callback(errorMsg)
        }
      }
    }

    // Finalize
    entry.data.values = tempValueArray

    // Post Data to Agilit-e
    tempArray = formatRecord(entry, tempArray)
    reqType = entry.custom.action === 'create' ? 'post' : 'put'

    // Create Headers Object
    headers['record-id'] = recordId

    postData(appId, reqType, entry, headers)
      .then((response) => {
        callback(null)

        entry.custom.action = ''
        entry.custom.isSavedDoc = true
        entry.key = response.data._id
        entry._id = response.data._id
        entry.data.isActiveDescription = response.data.data.isActive ? 'Yes' : 'No'

        for (const x in response.data.data.values) {
          response.data.data.values[x].key = uniqueId('tierstructures_')
        }

        // Dispatch New Array
        tempArray = updateArrayDataFromSave(entry, tempArray)
        dispatch({ type: actions.UPDATE_DATA, data: tempArray })
      })
      .catch((error) => {
        console.log(error)

        if (error.response) {
          callback(error.response.data.errorMessage)
        } else {
          callback(Enums.MESSAGES.UNKNOWN_ERROR)
        }
      })
  }
}
