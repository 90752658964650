import React, { useState, useEffect } from 'react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import ReCAPTCHA from 'react-google-recaptcha'
import AgiliteUtils from 'agilite-utils'

import Config from '../../utils/config'
import MemoryStore from '../../utils/memory-store'
import Enums from '../../utils/enums'

import { Card, Button, Input, Form, Row, Col, Spin, Alert, List, message } from 'antd'
import AgiliteHeader from '../../reusables/components/agilite-header'
import PasswordValidationItem from '../../reusables/components/password-validation-item'
const params = AgiliteUtils.getQueryParams(window.location.href)

const data = [
  {
    property: 'min',
    description: 'Minimum of 8 characters',
    complete: false
  },
  {
    property: 'symbols',
    description: 'Contains a symbol',
    complete: false
  },
  {
    property: 'digits',
    description: 'Contains a number',
    complete: false
  },
  {
    property: 'uppercase',
    description: 'Contains an uppercase letter',
    complete: false
  },
  {
    property: 'password',
    description: 'Passwords must match',
    complete: false
  }
]

const CoreResetPassword = ({ resetUser, validatePassword, verifyRecaptchaToken, verifyRecaptchaTokenv2 }) => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [alertMessage, setAlertMessage] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  const [lowScore, setLowScore] = useState(null)
  const [resetClicked, setResetClicked] = useState(false)
  const [resetDisabled, setResetDisabled] = useState(true)
  const [passwordValid, setPasswordValid] = useState({ value: false })

  const entry = MemoryStore.registerForm
  const reCaptchaEnabled = Config.reCaptcha?.enabled || false

  const onChange = (key, value) => {
    switch (key) {
      case 'inputPassword':
        MemoryStore.registerForm.password = value

        validatePassword(data, MemoryStore.registerForm.password, (result) => {
          // eslint-disable-next-line
          result.map((element) => {
            // eslint-disable-next-line
            data.map((obj) => {
              if (obj.property === element) {
                obj.complete = false
              }
            })
          })

          // Check if passwords match
          if (
            MemoryStore.registerForm.password === MemoryStore.registerForm.passwordConfirm &&
            MemoryStore.registerForm.password !== ''
          ) {
            data[4].complete = true
          } else {
            data[4].complete = false
          }

          if (result.length === 0 && MemoryStore.registerForm.passwordConfirm === MemoryStore.registerForm.password) {
            setPasswordValid(JSON.parse(JSON.stringify({ value: true })))
          } else {
            setPasswordValid(JSON.parse(JSON.stringify({ value: false })))
          }
        })
        break
      case 'inputPasswordConfirm':
        MemoryStore.registerForm.passwordConfirm = value

        // Check if passwords match
        if (
          MemoryStore.registerForm.passwordConfirm === MemoryStore.registerForm.password &&
          MemoryStore.registerForm.passwordConfirm !== ''
        ) {
          data[4].complete = true
          setPasswordValid(JSON.parse(JSON.stringify({ value: true })))
        } else {
          data[4].complete = false
          setPasswordValid(JSON.parse(JSON.stringify({ value: false })))
        }
        break
      default:
      // Do Nothing
    }
  }

  useEffect(() => {
    if (reCaptchaEnabled) {
      executeRecaptcha('reset_password')
        .then((result) => {
          verifyRecaptchaToken(result, (res, err) => {
            if (err) {
              setLowScore(true)

              if (err.response) {
                message.error(err.response.data.errorMessage)
              } else {
                message.error(Enums.MESSAGES.UNKNOWN_ERROR)
              }
              return
            }

            if (res.data.success) {
              if (res.data.score < 0.5) {
                setLowScore(true)
              } else {
                setLowScore(false)
                setResetDisabled(false)
              }
            } else {
              setLowScore(true)
            }
          })
        })
        .catch((err) => {
          setLowScore(true)
          console.log(err)
        })
    } else {
      setResetDisabled(false)
    }
    // eslint-disable-next-line
  }, [])

  const verifyRecaptcha = (token) => {
    verifyRecaptchaTokenv2(token, (res, err) => {
      if (err) {
        if (err.response) {
          message.error(err.response.data.errorMessage)
        } else {
          message.error(Enums.MESSAGES.UNKNOWN_ERROR)
        }
        return
      }

      if (res.data.success) {
        setResetDisabled(false)
      } else {
        message.error('reCaptcha Verification Failed')
      }
    })
  }

  return (
    <Row type='flex' justify='center'>
      <Col xs={23} sm={23} md={14} lg={12} xl={8} xxl={6}>
        <div>
          <div>
            <center>
              <AgiliteHeader header={Enums.VALUES_STRINGS.AGILITE_WELCOME} />
            </center>
          </div>
          <Card style={{ marginTop: 20 }} type='inner' title='Use the below Form to reset your password'>
            <Form style={{ textAlign: 'left' }}>
              <Row>
                <Col span={24}>
                  <space>
                    <Form.Item>
                      New Password
                      <Input.Password
                        placeholder='Enter a new password'
                        type='password'
                        required
                        defaultValue={entry.password}
                        onChange={(e) => {
                          onChange('inputPassword', e.target.value)
                        }}
                      />
                    </Form.Item>
                    <Form.Item>
                      Confirm New Password
                      <Input.Password
                        placeholder='Confirm the new password you entered'
                        type='password'
                        required
                        defaultValue={entry.passwordConfirm}
                        onChange={(e) => {
                          onChange('inputPasswordConfirm', e.target.value)
                        }}
                      />
                    </Form.Item>
                  </space>
                </Col>
              </Row>
              <Row>
                <List
                  style={{ marginBottom: 10, marginTop: 10 }}
                  dataSource={data}
                  renderItem={(item) => <PasswordValidationItem item={item} />}
                />
              </Row>
              <Row style={{ marginTop: 10 }}>
                <Button
                  disabled={!passwordValid.value || resetDisabled}
                  style={{
                    backgroundColor: '#67AD5B',
                    color: 'white',
                    width: 'auto',
                    marginRight: 10,
                    float: 'right'
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    setResetClicked(true)
                    resetUser(params.key, (successful, message) => {
                      if (successful === false) {
                        setResetClicked(false)
                        setAlertMessage(message)
                        setShowAlert(true)
                      } else {
                        setResetClicked(false)
                        setAlertMessage('')
                        setShowAlert('')
                      }
                    })
                  }}
                >
                  Submit
                </Button>
                <Button
                  style={{
                    backgroundColor: '#EB5181',
                    color: 'white',
                    width: 'auto',
                    marginRight: 10,
                    float: 'right'
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    const port = window.location.port !== '' ? ':' + window.location.port : ''
                    window.open(window.location.protocol + '//' + window.location.hostname + port, '_self')
                  }}
                >
                  Cancel
                </Button>
                {lowScore ? (
                  <div style={{ marginTop: 10 }}>
                    <ReCAPTCHA sitekey={Config.reCaptcha.v2_siteKey} onChange={verifyRecaptcha} />
                  </div>
                ) : null}
              </Row>
              <Row>
                {showAlert ? (
                  <div>
                    <Alert
                      style={{ marginTop: 10, marginBottom: 10 }}
                      message={alertMessage}
                      closable={false}
                      type='error'
                    />
                  </div>
                ) : null}
              </Row>
              {resetClicked === true ? (
                <Row style={{ marginTop: 30 }} justify='center'>
                  <Col span={24}>
                    <Spin size='large' />
                  </Col>
                </Row>
              ) : null}
            </Form>
          </Card>
          {reCaptchaEnabled ? (
            <Card style={{ textAlign: 'center', marginTop: 10 }}>
              This site is protected by reCAPTCHA and the Google
              <a href='https://policies.google.com/privacy' rel='noopener noreferrer' target='_blank'>
                {' '}
                Privacy Policy
              </a>{' '}
              and
              <a href='https://policies.google.com/terms' rel='noopener noreferrer' target='_blank'>
                {' '}
                Terms of Service
              </a>{' '}
              apply.
            </Card>
          ) : null}
        </div>
      </Col>
    </Row>
  )
}

export default CoreResetPassword
