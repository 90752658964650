import AgiliteUtils from 'agilite-utils'

import MemoryStore from '../utils/memory-store'
import { cleanupObject, isDuplicateEntry, updateArrayDataFromSave } from '../utils/utilities'
import { postData } from '../core/core-utils'
import { formatRecord } from './batch-logging-utils'
import Enums from '../utils/enums'

/*
  ENUMS
*/
export const actions = {
  RESET_STATE: 'RESET_STATE_BATCHLOGGING',
  CREATE_PROFILE: 'CREATE_PROFILE_BATCHLOGGING',
  SAVE_PROFILE: 'SAVE_PROFILE_BATCHLOGGING',
  CANCEL_PROFILE: 'CANCEL_PROFILE_BATCHLOGGING',
  EDIT_PROFILE: 'EDIT_PROFILE_BATCHLOGGING',
  DELETE_PROFILE: 'DELETE_PROFILE_BATCHLOGGING',
  FETCH_ALL_DATA: 'FETCH_ALL_DATA_BATCHLOGGING',
  SET_SEARCH_FILTER: 'SET_SEARCH_FILTER_BATCHLOGGING',
  UPDATE_DATA: 'UPDATE_DATA_BATCHLOGGING'
}

/*
  DISPATCH FUNCTIONS
*/
export const setSearchFilter = (value) => {
  return {
    type: actions.SET_SEARCH_FILTER,
    value
  }
}

export const updateData = (data) => {
  return {
    type: actions.UPDATE_DATA,
    data
  }
}

export const saveProfile = (appId, recordId, state, callback) => {
  return async (dispatch) => {
    const entry = MemoryStore.activeEntries[recordId]
    const headers = {}

    let result = true
    let reqType = ''
    let tempArray = state.data.concat()
    let errMsg = null

    // Cleanup and Trim
    cleanupObject(entry.data)
    await AgiliteUtils.trimObjectValues(entry.data)

    // Validation
    if (entry.data.key === '') {
      errMsg = 'Please provide a Profile Key'
      return callback(errMsg)
    }

    if (entry.data.name === '') {
      errMsg = 'Please provide a Profile Name'
      return callback(errMsg)
    }

    // Duplicate Check
    result = isDuplicateEntry(entry, 'key', tempArray, true)

    if (!result) {
      errMsg = `Profile Key '${entry.data.key}' is already being used`
      return callback(errMsg)
    }

    // Finalize

    // Create Headers Object
    headers['record-id'] = recordId

    // Post Data to Agilit-e
    tempArray = formatRecord(entry, tempArray)
    reqType = entry.custom.action === 'create' ? 'post' : 'put'

    postData(appId, reqType, entry, headers)
      .then((response) => {
        callback(null)

        entry.custom.action = ''
        entry.custom.isSavedDoc = true
        entry.key = response.data._id
        entry._id = response.data._id
        entry.data.isActiveDescription = response.data.data.isActive ? 'Yes' : 'No'

        tempArray = updateArrayDataFromSave(entry, tempArray)

        // Dispatch New Array
        dispatch({
          type: actions.UPDATE_DATA,
          data: tempArray
        })
      })
      .catch((error) => {
        console.log(error)

        if (error.response) {
          callback(error.response.data.errorMessage)
        } else {
          callback(Enums.MESSAGES.UNKNOWN_ERROR)
        }
      })
  }
}
