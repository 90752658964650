import React, { Component } from 'react'
import { Row, Col, Button, Form, Input, Switch } from 'antd'
import AgiliteUtils from 'agilite-utils'

import TableKeyValueEditableDND from '../../../reusables/components/table-key-value-editable-dnd'
import SolutionsContainer from '../../../reusables/containers/solutions-container'
import TooltipIcon from '../../../reusables/components/tooltip-icon'
import EnumsTooltips from '../../../utils/enums-tooltips'
import MemoryStore from '../../../utils/memory-store'

const { TextArea } = Input

class TierEntryFormGeneral extends Component {
  constructor(props) {
    super(props)

    this.updateValues = this.updateValues.bind(this)
    this.onFieldChange = this.onFieldChange.bind(this)
    this.formatTierEntryValues = this.formatTierEntryValues.bind(this)
  }

  UNSAFE_componentWillMount() {
    this.formatTierEntryValues(this.props.tierEntry.values)
  }

  updateValues(result) {
    MemoryStore.activeEntries[this.props.tabKey].custom.tierStructuresCustom.tierHistory[
      MemoryStore.activeEntries[this.props.tabKey].custom.tierStructuresCustom.tierHistory.length - 1
    ].isModified = true
    this.props.tierEntry.values = result
    this.forceUpdate()
  }

  onFieldChange(key, value) {
    MemoryStore.activeEntries[this.props.tabKey].custom.tierStructuresCustom.tierHistory[
      MemoryStore.activeEntries[this.props.tabKey].custom.tierStructuresCustom.tierHistory.length - 1
    ].isModified = true
    this.props.tierEntry[key] = value
    this.forceUpdate()
  }

  formatTierEntryValues(values) {
    if (values.length === 0) {
      values.push({
        rowKey: AgiliteUtils.uuidv1(),
        label: '',
        value: ''
      })
    }

    return values
  }

  render() {
    return (
      <Row type='flex' justify='space-between'>
        <Col xs={24} sm={24} md={24} lg={7}>
          <div className='row'>
            <Form>
              <Form.Item>
                <Switch
                  disabled={this.props.disabled}
                  checkedChildren='Active'
                  unCheckedChildren='Inactive'
                  checked={this.props.tierEntry.isActive}
                  onChange={(e) => {
                    this.onFieldChange('isActive', e)
                  }}
                />
              </Form.Item>
              <Form.Item>
                <span style={{ color: 'red' }}>* </span>
                {'Tier Key '}
                <TooltipIcon title={EnumsTooltips.general.profileKey} />
                <Input
                  name='key'
                  placeholder='Provide a unique Tier Key'
                  disabled={this.props.disabled}
                  value={this.props.tierEntry.key}
                  required
                  onChange={(e) => {
                    this.onFieldChange('key', e.target.value)
                  }}
                />
              </Form.Item>
              {this.props.tierPath.length === 1 ? (
                <SolutionsContainer
                  disabled={this.props.disabled}
                  defaultValue={this.props.tierEntry.solutions}
                  onValueChange={(value) => {
                    this.onFieldChange('solutions', value)
                  }}
                />
              ) : null}
              <Form.Item>
                Description (optional)
                <TextArea
                  className='description'
                  placeholder='A detailed description for this Profile'
                  rows={4}
                  disabled={this.props.disabled}
                  value={this.props.tierEntry.description}
                  onChange={(e) => {
                    this.onFieldChange('description', e.target.value)
                  }}
                />
              </Form.Item>
              <Form.Item>
                Notes (optional)
                <TextArea
                  className='notes'
                  placeholder='Internal Notes related to this Profile'
                  rows={4}
                  disabled={this.props.disabled}
                  value={this.props.tierEntry.notes}
                  onChange={(e) => {
                    this.onFieldChange('notes', e.target.value)
                  }}
                />
              </Form.Item>
              {!this.props.disabled ? (
                <Button
                  name='submit'
                  style={{ backgroundColor: '#67AD5B', color: 'white', width: 'auto', marginRight: 10 }}
                  onClick={() => {
                    this.props.saveProfile(this.props.tierEntry._id)
                  }}
                >
                  {this.props.isFirstTier ? 'Submit' : 'Ok'}
                </Button>
              ) : null}
              <Button
                name='cancel'
                style={{ backgroundColor: '#EB5181', color: 'white', width: 'auto', marginRight: 10 }}
                onClick={(e) => {
                  e.preventDefault()
                  this.props.cancelProfile()
                }}
              >
                {this.props.isFirstTier ? 'Cancel' : 'Back'}
              </Button>
            </Form>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={16}>
          <TableKeyValueEditableDND
            values={this.formatTierEntryValues(this.props.tierEntry.values)}
            theme={this.props.theme}
            privileges={this.props.privileges}
            disabled={this.props.disabled}
            callback={this.updateValues}
          />
        </Col>
      </Row>
    )
  }
}

export default TierEntryFormGeneral
