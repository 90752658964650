import React from 'react'
import uniqueId from 'lodash/uniqueId'
import update from 'immutability-helper'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash, faCog } from '@fortawesome/free-solid-svg-icons'
import { Card, Table, Input, Switch, Modal, Form, Select, Row, Col, Button, Tooltip, Space } from 'antd'
import { TranslationOutlined, DownCircleOutlined } from '@ant-design/icons'
import { DragSource, DropTarget } from 'react-dnd'
import { withDragDropContext } from './html5-backend'
import TableKeyValueEditableDND from './table-key-value-editable-dnd'
import MemoryStore from '../../utils/memory-store'
import SingleValueTableEditableDND from './single-value-table-editable-dnd'
import { cleanUpTableData } from '../../utils/utilities'
import LanguageTranslation from './language-translation'

let isDraggable = true

function dragDirection(dragIndex, hoverIndex, initialClientOffset, clientOffset, sourceClientOffset) {
  const hoverMiddleY = (initialClientOffset.y - sourceClientOffset.y) / 2
  const hoverClientY = clientOffset.y - sourceClientOffset.y

  if (dragIndex < hoverIndex && hoverClientY > hoverMiddleY) {
    return 'downward'
  }

  if (dragIndex > hoverIndex && hoverClientY < hoverMiddleY) {
    return 'upward'
  }
}

class BodyRow extends React.Component {
  render() {
    const {
      isOver,
      connectDragSource,
      connectDropTarget,
      moveRow,
      dragRow,
      clientOffset,
      sourceClientOffset,
      initialClientOffset,
      ...restProps
    } = this.props

    const style = {
      ...restProps.style,
      cursor: 'move'
    }

    let className = restProps.className

    if (isOver && initialClientOffset) {
      const direction = dragDirection(
        dragRow.index,
        restProps.index,
        initialClientOffset,
        clientOffset,
        sourceClientOffset
      )

      if (direction === 'downward') {
        className += ' drop-over-downward'
      }

      if (direction === 'upward') {
        className += ' drop-over-upward'
      }
    }

    return connectDragSource(connectDropTarget(<tr {...restProps} className={className} style={style} />))
  }
}

const rowSource = {
  beginDrag(props) {
    return { index: props.index }
  },
  canDrag() {
    return isDraggable
  }
}

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex)

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex
  }
}

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  sourceClientOffset: monitor.getSourceClientOffset()
}))(
  DragSource('row', rowSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    dragRow: monitor.getItem(),
    clientOffset: monitor.getClientOffset(),
    initialClientOffset: monitor.getInitialClientOffset()
  }))(BodyRow)
)

class DragSortingTable extends React.Component {
  constructor(props) {
    super(props)

    this.components = {
      body: {
        row: DragableBodyRow
      }
    }

    this.trashEnabled = null
    this.tmpInputOptions = {}

    if (this.props.entry.visibleObjects.length === 0) {
      this.props.entry.visibleObjects.push(JSON.parse(JSON.stringify(MemoryStore.visibleObjectsTemplate)))
    }

    this.state = {
      data: this.formatData(props.entry.visibleObjects),
      open: false,
      modalEntry: {},
      key: null,
      inputType: '',
      showPopulateButton: props.showPopulateButton,
      populateLabel: props.populateLabel,
      template: props.template,
      languageModalVisible: false
    }

    this.trashEnabled = this.state.data.length > 1

    // Setup Event Binding
    this.handleMoveRow = this.handleMoveRow.bind(this)
    this.handleDndTableChange = this.handleDndTableChange.bind(this)
    this.onChange = this.onChange.bind(this)
    this.onAdd = this.onAdd.bind(this)
    this.onDelete = this.onDelete.bind(this)
    this.onOptionsChange = this.onOptionsChange.bind(this)
    this.handleCancelInputOptions = this.handleCancelInputOptions.bind(this)
    this.handleSaveInputOptions = this.handleSaveInputOptions.bind(this)
    this.openInputOptions = this.openInputOptions.bind(this)
    this.updateMessages = this.updateMessages.bind(this)
    this.updateChoices = this.updateChoices.bind(this)
    this.populateFromTemplate = this.populateFromTemplate.bind(this)
    this.handleDataChange = this.handleDataChange.bind(this)
    this.handleDataSubmit = this.handleDataSubmit.bind(this)
  }

  handleMoveRow(dragIndex, hoverIndex) {
    if (this.props.disabled) {
      return null
    }

    this.setState({
      data: this.formatData(this.props.entry.visibleObjects)
    })

    const { data } = this.state
    const dragRow = data[dragIndex]
    const tmpThis = this

    tmpThis.setState(
      update(tmpThis.state, {
        data: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow]
          ]
        }
      })
    )

    return this.props.callback(
      tmpThis.state.data.map((entry) => {
        return entry.entry
      })
    )
  }

  handleDndTableChange(key, value, index, isLanguageTranslation) {
    if (isLanguageTranslation) {
      this.tmpInputOptions[key][index] = value
    } else {
      this.tmpInputOptions[key][index] = { value }
    }
  }

  onChange(index, key, value) {
    const tmpData = JSON.parse(JSON.stringify(this.state.data))
    let tmpIndex = null

    tmpIndex = this.state.data.findIndex((entry) => {
      return entry.key === index
    })

    if (key === 'isEditable' && value === false) {
      this.props.entry.visibleObjects[tmpIndex].isMandatory = value
      tmpData[tmpIndex].entry.isMandatory = value
    }

    this.props.entry.visibleObjects[tmpIndex][key] = value
    tmpData[tmpIndex].entry[key] = value

    this.setState((prevState) => ({
      ...prevState,
      data: tmpData
    }))
  }

  onAdd(index) {
    const data = this.state.data.concat()

    const entry = { entry: JSON.parse(JSON.stringify(MemoryStore.visibleObjectsTemplate)) }
    entry.key = uniqueId()

    let tmpIndex = null

    tmpIndex = this.state.data.findIndex((entry) => {
      return entry.key === index
    })

    tmpIndex++

    this.props.entry.visibleObjects.splice(tmpIndex, 0, JSON.parse(JSON.stringify(MemoryStore.visibleObjectsTemplate)))

    data.splice(tmpIndex, 0, entry)
    this.trashEnabled = data.length > 1

    this.setState({ data })

    return true
  }

  onDelete(index) {
    const data = this.state.data
    let tmpIndex = ''

    tmpIndex = this.state.data.findIndex((entry) => {
      return entry.key === index
    })

    if (!this.trashEnabled) {
      data.push({ entry: JSON.parse(JSON.stringify(MemoryStore.visibleObjectsTemplate)), key: uniqueId() })
      this.props.entry.visibleObjects.push(JSON.parse(JSON.stringify(MemoryStore.visibleObjectsTemplate)))
    }

    if (data.length === 2) {
      this.trashEnabled = false
    }

    data.splice(tmpIndex, 1)
    this.props.entry.visibleObjects.splice(tmpIndex, 1)

    this.setState({
      data
    })

    return true
  }

  onOptionsChange(key, value) {
    this.tmpInputOptions[key] = value
  }

  handleCancelInputOptions() {
    this.tmpInputOptions = {}

    this.setState({
      modalEntry: {},
      open: false,
      inputType: '',
      key: null
    })
  }

  handleSaveInputOptions() {
    this.tmpInputOptions.messages = cleanUpTableData(this.tmpInputOptions.messages, false)
    this.tmpInputOptions.choices = cleanUpTableData(this.tmpInputOptions.choices, true)
    this.props.entry.visibleObjects[this.state.key].inputOptions = this.tmpInputOptions
    this.tmpInputOptions = {}

    this.setState({
      modalEntry: {},
      open: false,
      inputType: '',
      key: null,
      data: this.formatData(this.props.entry.visibleObjects)
    })
  }

  openInputOptions(record) {
    const entry = record.entry.inputOptions
      ? JSON.parse(JSON.stringify(record.entry.inputOptions))
      : JSON.parse(JSON.stringify(MemoryStore.visibleObjectsTemplate.inputOptions))

    if (!entry.iln.label) entry.iln.label = entry.label
    this.tmpInputOptions = entry

    const tmpIndex = this.state.data.findIndex((entry) => {
      return entry.key === record.key
    })

    if (this.tmpInputOptions.messages.length === 0) {
      this.tmpInputOptions.messages.push({
        value: '',
        iln: {}
      })
    }

    this.setState({
      open: true,
      modalEntry: entry,
      inputType: record.entry.inputOptions.inputType,
      key: tmpIndex
    })
  }

  updateMessages(data) {
    this.tmpInputOptions.messages = data
  }

  updateChoices(data) {
    this.tmpInputOptions.choices = data
  }

  formatData(data) {
    data = data.map((entry) => {
      return {
        entry,
        key: uniqueId()
      }
    })

    return data
  }

  populateFromTemplate() {
    let data = JSON.parse(JSON.stringify(this.state.template))
    this.props.populateFromTemplate(data)
    data = this.formatData(data)
    return this.setState({ data })
  }

  handleDataChange(data) {
    this.tmpInputOptions.iln.label = data
  }

  handleDataSubmit() {
    this.setState({ languageModalVisible: false })
  }

  render() {
    const columns = [
      {
        title: 'Object Id',
        key: 'id',
        render: (text, record, index) => (
          <Input
            cypressid='objectId'
            placeholder='Provide a Id {{}}'
            disabled={this.props.disabled}
            defaultValue={record.entry ? (record.entry.id ? record.entry.id : '') : ''}
            onChange={(e) => {
              this.onChange(record.key, 'id', e.target.value)
            }}
            onFocus={() => {
              isDraggable = false
            }}
            onBlur={() => {
              isDraggable = true
            }}
          />
        )
      },
      {
        title: 'Editable for Responsible Role',
        key: 'editable',
        render: (text, record) => (
          <Switch
            cypressid='editable'
            disabled={this.props.disabled}
            checked={record.entry.isEditable}
            onChange={(value) => {
              this.onChange(record.key, 'isEditable', value)
            }}
          />
        )
      },
      {
        title: 'Mandatory if Input Field',
        key: 'mandatory',
        render: (text, record) => (
          <Switch
            cypressid='mandatory'
            disabled={this.props.disabled}
            checked={record.entry.isMandatory}
            onChange={(value) => {
              this.onChange(record.key, 'isMandatory', value)
            }}
          />
        )
      },
      {
        title: 'Actions',
        key: 'actions',
        width: 150,
        render: (text, record) => (
          <div style={{ minWidth: 50 }}>
            <div
              className={process.env.NODE_ENV === 'development' ? 'row-icon row-icon-show' : 'row-icon row-icon-hide'}
            >
              <FontAwesomeIcon
                cypressid='inputOptions'
                icon={faCog}
                style={{ color: '#4994EC', cursor: 'pointer' }}
                onClick={() => this.openInputOptions(record)}
                hidden={this.props.disabled}
              />
            </div>
            <div
              className={process.env.NODE_ENV === 'development' ? 'row-icon row-icon-show' : 'row-icon row-icon-hide'}
            >
              <FontAwesomeIcon
                cypressid='delete'
                icon={faTrash}
                style={{ color: this.props.theme.dangerColor, cursor: 'pointer', marginLeft: 20 }}
                onClick={() => this.onDelete(record.key)}
                hidden={this.props.disabled}
              />
            </div>
            <div
              className={process.env.NODE_ENV === 'development' ? 'row-icon row-icon-show' : 'row-icon row-icon-hide'}
            >
              <FontAwesomeIcon
                cypressid='add'
                icon={faPlus}
                style={{ color: this.props.theme.successColor, cursor: 'pointer', marginLeft: 20 }}
                onClick={() => this.onAdd(record.key)}
                hidden={this.props.disabled}
              />
            </div>
          </div>
        )
      }
    ]

    return (
      <div>
        <Card title={this.props.title}>
          {this.state.showPopulateButton ? (
            <Button
              icon={<DownCircleOutlined />}
              type='default'
              style={{ color: '#ff9800', marginBottom: 20 }}
              onClick={(e) => {
                e.preventDefault()
                this.populateFromTemplate()
              }}
            >
              {this.state.populateLabel}
            </Button>
          ) : null}
          <Table
            id='single-value-table-editable-dnd'
            dataSource={Object.assign([], this.state.data)}
            bordered
            columns={columns}
            size='middle'
            pagination={false}
            components={this.components}
            onRow={(record, index) => ({
              index,
              moveRow: this.handleMoveRow
            })}
          />
        </Card>
        <Modal
          title='Input Options'
          okButtonProps={{ style: { backgroundColor: '#67AD5B', borderColor: '#67AD5B' } }}
          modal
          width={1000}
          visible={this.state.open}
          okText='Ok'
          onCancel={this.handleCancelInputOptions}
          onOk={this.handleSaveInputOptions}
          destroyOnClose
          maskClosable={false}
        >
          <Row>
            <Col span={24}>
              <Row>
                <Col xs={24} sm={24} md={10} lg={10}>
                  <Form layout='vertical'>
                    <Form.Item>
                      Input Label
                      <Tooltip title='Add language alternatives for field value'>
                        <TranslationOutlined
                          onClick={() => this.setState({ languageModalVisible: true, dataKey: 'name' })}
                          style={{ color: 'blueviolet', fontSize: 16, marginLeft: 8 }}
                        />
                      </Tooltip>
                      <Input
                        cypressid='label'
                        name='label'
                        placeholder='Provide a label for the Input Field'
                        defaultValue={this.tmpInputOptions.label}
                        onChange={(e) => {
                          this.onOptionsChange('label', e.target.value)
                        }}
                      />
                    </Form.Item>
                    <Form.Item>
                      Input Type
                      <Select
                        cypressid='inputType'
                        className='type'
                        defaultValue={this.tmpInputOptions.inputType}
                        onChange={(e) => {
                          this.onOptionsChange('inputType', e)
                          this.setState({ inputType: e })
                        }}
                      >
                        <Select.Option value='text'>Text</Select.Option>
                        <Select.Option value='number'>Number</Select.Option>
                        <Select.Option value='choice'>Choices</Select.Option>
                        <Select.Option value='date'>Date</Select.Option>
                        <Select.Option value='file'>File</Select.Option>
                      </Select>
                    </Form.Item>
                    {this.state.inputType === 'file' ? (
                      <>
                        <Form.Item label='Multiple Files Allowed'>
                          <Switch
                            unCheckedChildren='No'
                            checkedChildren='Yes'
                            valuePropName='checked'
                            onChange={(checked) => {
                              this.onOptionsChange('multipleFiles', checked)
                            }}
                            defaultChecked={this.tmpInputOptions.multipleFiles}
                          />
                        </Form.Item>
                        <Form.Item label='Accepted File Types (Comma Separated)'>
                          <Input
                            placeholder='e.g .png,.jpg,.jpeg,.pdf'
                            onChange={(e) => {
                              this.onOptionsChange('acceptedFileTypes', e.target.value)
                            }}
                            defaultValue={this.tmpInputOptions.acceptedFileTypes}
                          />
                        </Form.Item>
                      </>
                    ) : undefined}
                  </Form>
                </Col>
              </Row>
              <Row>
                <Col xs={24} sm={24} md={24} lg={24}>
                  {this.state.inputType === 'choice' ? (
                    <div>
                      <h3 style={{ marginBottom: 0 }}>List of Choices</h3>
                      <TableKeyValueEditableDND
                        values={this.tmpInputOptions.choices}
                        privileges={MemoryStore.userProfile.teamPrivileges.bpm}
                        disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
                        callback={this.updateChoices}
                        theme={this.props.theme}
                        // eslint-disable-next-line
                        languageTranslationEnabled={true}
                      />
                    </div>
                  ) : null}
                  <div style={{ marginTop: 20 }}>
                    <SingleValueTableEditableDND
                      data={this.tmpInputOptions.messages}
                      disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
                      title='Validation/Prompt Messages'
                      theme={this.props.theme}
                      callback={this.updateMessages}
                      onChange={this.handleDndTableChange}
                      arrayKey='messages'
                      // eslint-disable-next-line
                      languageTranslationEnabled={true}
                      privileges={MemoryStore.userProfile.teamPrivileges.bpm}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Modal>
        <Modal
          title='Language Translate'
          visible={this.state.languageModalVisible}
          onOk={this.handleDataSubmit}
          onCancel={() => this.setState({ languageModalVisible: false })}
          closable={false}
          width='50%'
          destroyOnClose
          okButtonProps={{
            style: {
              backgroundColor: '#67AD5B',
              color: 'white'
            }
          }}
          okText='Submit'
        >
          <LanguageTranslation
            theme={this.props.theme}
            disabled={MemoryStore.userProfile.teamPrivileges.bpm === 'Reader'}
            data={
              this.tmpInputOptions.iln ? (this.tmpInputOptions.iln.label ? this.tmpInputOptions.iln.label : {}) : {}
            }
            dataKey='label'
            defaultLanguage={MemoryStore.defaultLanguage}
            privileges={MemoryStore.userProfile.teamPrivileges.bpm}
            fieldValue={this.tmpInputOptions.label ? this.tmpInputOptions.label : ''}
            onDataChange={this.handleDataChange}
          />
        </Modal>
      </div>
    )
  }
}

const VisibleObjectsTableEditableDND = withDragDropContext(DragSortingTable)

export default VisibleObjectsTableEditableDND
