import AgiliteUtils from 'agilite-utils'

import MemoryStore from '../utils/memory-store'
import { cleanupObject, updateArrayDataFromSave, cleanUpTableData } from '../utils/utilities'
import { postData } from '../core/core-utils'
import { formatRecord } from './roles-utils'
import Enums from '../utils/enums'

/*
  ENUMS
*/
export const actions = {
  RESET_STATE: 'RESET_STATE_ROLES',
  CREATE_PROFILE: 'CREATE_PROFILE_ROLES',
  SAVE_PROFILE: 'SAVE_PROFILE_ROLES',
  CANCEL_PROFILE: 'CANCEL_PROFILE_ROLES',
  EDIT_PROFILE: 'EDIT_PROFILE_ROLES',
  DELETE_PROFILE: 'DELETE_PROFILE_ROLES',
  FETCH_ALL_DATA: 'FETCH_ALL_DATA_ROLES',
  SET_SEARCH_FILTER: 'SET_SEARCH_FILTER_ROLES',
  UPDATE_DATA: 'UPDATE_DATA_ROLES'
}

/*
  DISPATCH FUNCTIONS
*/
export const setSearchFilter = (value) => {
  return {
    type: actions.SET_SEARCH_FILTER,
    value
  }
}

export const updateData = (data) => {
  return {
    type: actions.UPDATE_DATA,
    data
  }
}

export const saveProfile = (appId, recordId, state, callback) => {
  return async (dispatch) => {
    const entry = MemoryStore.activeEntries[recordId]
    const headers = {}

    let recordLevels = null
    let tmpIndex = null
    let reqType = ''
    let tempArray = state.data.concat()
    let recordArray = []
    let errMsg = ''

    let nameMatch = false
    let responsibleUserMatch = false
    let conditionalLevelMatch = false

    // Create new array of tempArray so we can modify and work with it
    recordArray = tempArray.concat()

    // Cleanup and Trim
    cleanupObject(entry.data)
    await AgiliteUtils.trimObjectValues(entry.data)

    // Validation
    if (!entry.data.name) errMsg = 'Please provide a Role Name'
    if (!entry.data.tmpResponsibleUser) errMsg = 'Please provide a Responsible User'

    // If existing record remove it from tempArray as we don't want to compare it to itself
    tmpIndex = recordArray.findIndex((profile) => profile._id === recordId)
    if (tmpIndex !== -1) recordArray.splice(tmpIndex, 1)

    // Check for duplicate roles
    for (const record of recordArray) {
      nameMatch = false
      responsibleUserMatch = false
      conditionalLevelMatch = false

      recordLevels = record.data.levels.map((level) => level.value)

      if (entry.data.name === record.data.name) nameMatch = true

      for (const responsibleUser of entry.data.tmpResponsibleUser.split(',')) {
        if (record.data.responsibleUser.includes(responsibleUser)) responsibleUserMatch = true
      }

      if (entry.data.levels.length === 0 && recordLevels.length === 0) {
        conditionalLevelMatch = true
      } else {
        for (const level of entry.data.levels.map((level) => level.value)) {
          if (recordLevels.includes(level)) conditionalLevelMatch = true
        }
      }

      if (nameMatch && responsibleUserMatch && conditionalLevelMatch) {
        errMsg = `Duplicate for Role '${entry.data.name}' found. Please revise`
        break
      }
    }

    if (errMsg) return callback(errMsg)

    // Finalize
    cleanUpTableData(entry.data.levels, false)

    // Post Data to Agilit-e
    tempArray = formatRecord(entry, tempArray)
    reqType = entry.custom.action === 'create' ? 'post' : 'put'

    // Create Headers Object
    headers['record-id'] = recordId

    postData(appId, reqType, entry, headers)
      .then((response) => {
        callback(null)

        response.data.custom.action = ''
        response.data.custom.isSavedDoc = true

        // Setup Fields for Table
        if (!response.data.key) response.data.key = response.data._id

        if (response.data.data && response.data.data.isActive !== undefined) {
          response.data.data.isActiveDescription = response.data.data.isActive ? 'Yes' : 'No'
        }

        response.data.data.tmpResponsibleUser = response.data.data.responsibleUser.join(',')
        response.data.data.tmpLevels = []

        for (const x in response.data.data.levels) response.data.data.tmpLevels.push(response.data.data.levels[x].value)

        response.data.data.tmpLevels =
          response.data.data.tmpLevels.length > 0 ? response.data.data.tmpLevels.join(' - ') : ''

        // Dispatch New Array
        tempArray = updateArrayDataFromSave(response.data, tempArray)
        dispatch({ type: actions.UPDATE_DATA, data: tempArray })
      })
      .catch((error) => {
        console.log(error)

        if (error.response) {
          callback(error.response.data.errorMessage)
        } else {
          callback(Enums.MESSAGES.UNKNOWN_ERROR)
        }
      })
  }
}
