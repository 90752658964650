import React from 'react'

import { Row, Col, Form, Input, Select } from 'antd'

import MemoryStore from '../../../../../utils/memory-store'
import 'brace/mode/json'
import 'brace/theme/xcode'

class SFTP extends React.Component {
  constructor(props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      entry: props.entry
    }

    this.onFieldChange = this.onFieldChange.bind(this)
  }

  onFieldChange(key, value) {
    this.entry.custom.isModified = true
    this.props.entry[key] = value
  }

  render() {
    return (
      <Row>
        <Col xs={24} lg={16}>
          <Form.Item>
            Host
            <Input
              name='host'
              placeholder='Defaults to localhost'
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              defaultValue={this.state.entry.host}
              onChange={(e) => {
                this.onFieldChange('host', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            Port
            <Input
              name='port'
              placeholder='Defaults to 22'
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              defaultValue={this.state.entry.port}
              onChange={(e) => {
                this.onFieldChange('port', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>
            Username
            <Input
              name='username'
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              defaultValue={this.state.entry.username}
              autoComplete='new-password'
              onChange={(e) => {
                this.onFieldChange('username', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>
            Password
            <Input
              name='password'
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              defaultValue={this.state.entry.password}
              autoComplete='new-password'
              type='password'
              onChange={(e) => {
                this.onFieldChange('password', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            Private Key
            <Input
              name='privateKey'
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              defaultValue={this.state.entry.privateKey}
              onChange={(e) => {
                this.onFieldChange('privateKey', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            Passphrase
            <Input
              name='passphrase'
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              defaultValue={this.state.entry.passphrase}
              onChange={(e) => {
                this.onFieldChange('passphrase', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            Server Host Keys
            <Select
              name='serverHostKey'
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              defaultValue={this.state.entry.serverHostKey}
              onChange={(value) => {
                console.log(value)
                this.onFieldChange('serverHostKey', value)
              }}
              mode='multiple'
              options={[
                {
                  label: 'ssh-dss',
                  value: 'ssh-dss'
                },
                {
                  label: 'ssh-rsa',
                  value: 'ssh-rsa'
                }
              ]}
            />
          </Form.Item>
        </Col>
      </Row>
    )
  }
}

export default SFTP
