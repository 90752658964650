import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { DragSource, DropTarget } from 'react-dnd'
import { withDragDropContext } from './html5-backend'
import update from 'immutability-helper'
import { Table, Form, Input, Select } from 'antd'
import AgiliteUtils from 'agilite-utils'

import '../reusables-views.css'

let isDraggable = true

function dragDirection(dragIndex, hoverIndex, initialClientOffset, clientOffset, sourceClientOffset) {
  const hoverMiddleY = (initialClientOffset.y - sourceClientOffset.y) / 2
  const hoverClientY = clientOffset.y - sourceClientOffset.y

  if (dragIndex < hoverIndex && hoverClientY > hoverMiddleY) {
    return 'downward'
  }

  if (dragIndex > hoverIndex && hoverClientY < hoverMiddleY) {
    return 'upward'
  }
}

class BodyRow extends React.Component {
  render() {
    const {
      isOver,
      connectDragSource,
      connectDropTarget,
      moveRow,
      dragRow,
      clientOffset,
      sourceClientOffset,
      initialClientOffset,
      ...restProps
    } = this.props

    const style = {
      ...restProps.style,
      cursor: 'move'
    }

    let className = restProps.className

    if (isOver && initialClientOffset) {
      const direction = dragDirection(
        dragRow.index,
        restProps.index,
        initialClientOffset,
        clientOffset,
        sourceClientOffset
      )

      if (direction === 'downward') {
        className += ' drop-over-downward'
      }

      if (direction === 'upward') {
        className += ' drop-over-upward'
      }
    }

    return connectDragSource(connectDropTarget(<tr {...restProps} className={className} style={style} />))
  }
}

const rowSource = {
  beginDrag(props) {
    return { index: props.index }
  },
  canDrag() {
    return isDraggable
  }
}

const rowTarget = {
  drop(props, monitor) {
    const dragIndex = monitor.getItem().index
    const hoverIndex = props.index

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return
    }

    // Time to actually perform the action
    props.moveRow(dragIndex, hoverIndex)

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex
  }
}

const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
  sourceClientOffset: monitor.getSourceClientOffset()
}))(
  DragSource('row', rowSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    dragRow: monitor.getItem(),
    clientOffset: monitor.getClientOffset(),
    initialClientOffset: monitor.getInitialClientOffset()
  }))(BodyRow)
)
class DragSortingTable extends React.Component {
  constructor(props) {
    super(props)

    this.moveRow = this.moveRow.bind(this)

    this.columns = [
      {
        title: this.props.columnTitle || 'Key',
        width: '47.5%',
        dataIndex: 'key',
        key: 'key',
        render: (text, record, index) => {
          return (
            <Form.Item>
              <Input
                placeholder={`Provide a ${this.props.columnTitle || 'Key'}`}
                disabled={this.props.privileges === 'Reader'}
                value={this.props.fields ? record[this.props.fields[0]] : record.paramKey}
                style={{ width: '100%' }}
                onChange={(e) => {
                  this.changeRow(index, this.props.fields ? this.props.fields[0] : 'paramKey', e.target.value)
                }}
                onFocus={() => {
                  isDraggable = false
                }}
                onBlur={() => {
                  isDraggable = true
                }}
              />
            </Form.Item>
          )
        }
      },
      {
        title: 'Type',
        width: '47.5%',
        dataIndex: 'type',
        key: 'type',
        render: (text, record, index) => {
          return (
            <Form.Item>
              <Select
                placeholder='Provide a Type'
                disabled={this.props.privileges === 'Reader'}
                value={
                  record.paramType ? record.paramType : this.props.defaultTypeValue ? this.props.defaultTypeValue : ''
                }
                style={{ width: '100%' }}
                onChange={(value) => {
                  this.changeRow(index, this.props.fields ? this.props.fields[1] : 'paramType', value)
                }}
                onFocus={() => {
                  isDraggable = false
                }}
                onBlur={() => {
                  isDraggable = true
                }}
              >
                <Select.Option value=''>-None-</Select.Option>
                {this.props.selectOptions}
              </Select>
            </Form.Item>
          )
        }
      },
      {
        title: 'Actions',
        width: '5%',
        dataIndex: 'actions',
        render: (text, record, index) => {
          return (
            <div style={{ minWidth: 50 }}>
              <div
                className={process.env.NODE_ENV === 'development' ? 'row-icon row-icon-show' : 'row-icon row-icon-hide'}
              >
                {this.state.data.length > 1 && this.props.privileges !== 'Reader' ? (
                  /* eslint-disable-next-line */
                  <a
                    onClick={(e) => {
                      e.preventDefault()
                      this.deleteRow(index)
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      style={{ color: this.props.theme.dangerColor }}
                      hidden={this.props.disabled}
                    />
                  </a>
                ) : (
                  <FontAwesomeIcon icon={faTrash} hidden={this.props.disabled} />
                )}
              </div>
              {this.props.privileges !== 'Reader' ? (
                <div
                  style={{ marginLeft: 15 }}
                  className={
                    process.env.NODE_ENV === 'development' ? 'row-icon row-icon-show' : 'row-icon row-icon-hide'
                  }
                >
                  {/* eslint-disable-next-line */}
                  <a
                    onClick={(e) => {
                      e.preventDefault()
                      this.addRow(index)
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faPlus}
                      style={{ color: this.props.theme.successColor }}
                      hidden={this.props.disabled}
                    />
                  </a>
                </div>
              ) : null}
            </div>
          )
        }
      }
    ]

    this.components = {
      body: {
        row: DragableBodyRow
      }
    }

    if (props.values.length === 0) {
      props.values.push({
        rowKey: AgiliteUtils.uuidv1(),
        paramKey: '',
        paramType: ''
      })
    }

    this.state = {
      data: this.props.values
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ data: nextProps.values })
  }

  generateTableTitle() {
    return <h2>Key/Type Listing</h2>
  }

  moveRow(dragIndex, hoverIndex) {
    const { data } = this.state
    const dragRow = data[dragIndex]

    this.setState(
      update(this.state, {
        data: {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRow]
          ]
        }
      })
    )

    return this.props.callback(this.state.data)
  }

  addRow(index) {
    const data = this.state.data
    const tmpIndex = index + 1

    const entry = {
      rowKey: AgiliteUtils.uuidv1(),
      paramKey: '',
      paramType: ''
    }

    data.splice(tmpIndex, 0, entry)
    this.setState({ data })

    return this.props.callback(this.state.data)
  }

  deleteRow(index) {
    const data = this.state.data

    data.splice(index, 1)
    this.setState({ data })

    return this.props.callback(this.state.data)
  }

  changeRow(index, field, value) {
    const data = this.state.data

    data[index][field] = value
    this.setState({ data })

    return this.props.callback(this.state.data)
  }

  render() {
    return (
      <Table
        id='table-key-type'
        size='middle'
        bordered
        pagination={false}
        showHeader
        columns={this.columns}
        dataSource={Object.assign([], this.state.data)}
        components={this.components}
        onRow={(record, index) => ({
          index,
          moveRow: this.moveRow
        })}
        rowKey={(record) => record.rowKey}
      />
    )
  }
}

const TableKeyTypeEditableDnd = withDragDropContext(DragSortingTable)

export default TableKeyTypeEditableDnd
