import React, { useState, useEffect } from 'react'
import AgiliteUtils from 'agilite-utils'

import TableKeyValueEditableDND from './table-key-value-editable-dnd'
import TypeDetect from 'agilite-utils/dist/type-detect'
import MemoryStore from '../../utils/memory-store'

const LanguageTranslation = ({ onDataChange, disabled, privileges, theme, data, defaultLanguage, fieldValue }) => {
  const [langData, setLangData] = useState([])

  useEffect(() => {
    formatLanguages()
    // eslint-disable-next-line
  }, [])

  const formatLanguages = () => {
    const tmpArray = []
    let tmpIndex = null
    let tmpData = null

    if (TypeDetect(data) !== 'Object') {
      tmpData = {}
    } else {
      tmpData = Object.assign({}, data)
    }

    if (Object.keys(tmpData).length === 0) {
      tmpIndex = MemoryStore.isoLanguages.findIndex((entry) => {
        return entry.value === defaultLanguage
      })
      tmpData[MemoryStore.isoLanguages[tmpIndex].value] = fieldValue
    }

    for (const langCode in tmpData) {
      tmpIndex = MemoryStore.isoLanguages.findIndex((entry) => {
        return entry.value === langCode
      })

      if (tmpIndex !== -1) {
        if (langCode === defaultLanguage) {
          tmpArray.push({
            rowKey: AgiliteUtils.uuidv1(),
            label: MemoryStore.isoLanguages[tmpIndex].value,
            value: tmpData[langCode],
            readOnly: true
          })
        } else {
          tmpArray.push({
            rowKey: AgiliteUtils.uuidv1(),
            label: MemoryStore.isoLanguages[tmpIndex].value,
            value: tmpData[langCode],
            readOnly: false
          })
        }
      }
    }

    setLangData(tmpArray)
  }

  const _handleDataChange = (newData) => {
    const tmpData = {}
    let propKey = null
    let propValue = null

    for (const x in newData) {
      if (newData[x].label && newData[x].value) {
        propKey = newData[x].label.trim()
        propValue = newData[x].value.trim()

        tmpData[propKey] = propValue
      }
    }

    onDataChange(tmpData)
  }

  return (
    <TableKeyValueEditableDND
      // eslint-disable-next-line
      values={langData ? langData : [{ label: '', value: '' }]}
      columnTitle='ISO Language'
      columnWidth='25%'
      valueColumnTitle='Translated Value'
      valueColumnWidth='50%'
      // eslint-disable-next-line
      typeAheadLabelInput={true}
      typeAheadLabelInputData={MemoryStore.isoLanguages}
      theme={theme}
      privileges={privileges}
      disabled={disabled}
      callback={(data) => _handleDataChange(data)}
    />
  )
}

export default LanguageTranslation
