import coreState from './core-state'
import { actions } from './core-actions'
import MemoryStore from '../utils/memory-store'
import Enums from '../utils/enums'

export default (state = coreState, action) => {
  let tabArray = null
  let index = null
  let tmpObj = {}

  switch (action.type) {
    case actions.SIGN_IN_USER:
      return Object.assign({}, state, {
        facet: '',
        user: {
          loggedIn: true,
          signInClicked: false
        }
      })
    case actions.SIGN_IN_CLICKED:
      return Object.assign({}, state, {
        user: {
          signInClicked: true
        }
      })
    case actions.LOGIN_FAILED:
      return Object.assign({}, state, {
        user: {
          signInClicked: false
        }
      })
    case actions.SIGN_OUT_USER:
      MemoryStore.registerForm = {}
      MemoryStore.apiKey = ''
      MemoryStore.userProfile = {}

      tmpObj = JSON.parse(JSON.stringify(coreState))

      tmpObj.facet = Enums.FACETS.SIGN_IN

      return Object.assign({}, state, tmpObj)
    case actions.INIT_FORGOT_PASSWORD_FORM:
      return Object.assign({}, state, {
        facet: Enums.FACETS.FORGOT
      })
    case actions.INIT_FORGOT_PASSWORD_CONFIRMATION_PAGE:
      return Object.assign({}, state, {
        facet: Enums.FACETS.FORGOT_CONFIRM
      })
    case actions.INIT_REGISTER_FORM:
      action.key = action.key ? action.key : Enums.MEMBERSHIP_TYPES.INDIE
      MemoryStore.registerForm.membershipType = action.key

      return Object.assign({}, state, {
        facet: Enums.FACETS.REGISTER
      })
    case actions.INIT_REGISTER_CONFIRMATION_PAGE:
      return Object.assign({}, state, {
        facet: Enums.FACETS.REGISTER_CONFIRM
      })
    case actions.INIT_RESET_FORM:
      MemoryStore.registerForm.id = action.key

      return Object.assign({}, state, {
        facet: Enums.FACETS.RESET
      })
    case actions.INIT_RESET_CONFIRMATION_PAGE:
      return Object.assign({}, state, {
        facet: Enums.FACETS.RESET_CONFIRM
      })
    case actions.INIT_ACTIVATE_FORM:
      MemoryStore.registerForm.id = action.key

      return Object.assign({}, state, {
        facet: Enums.FACETS.ACTIVATE
      })
    case actions.INIT_ACTIVATE_CONFIRMATION_PAGE:
      return Object.assign({}, state, {
        facet: Enums.FACETS.ACTIVATE_CONFIRMATION
      })
    case actions.INIT_ACTIVATE_SUCCESS_PAGE:
      return Object.assign({}, state, {
        facet: Enums.FACETS.ACTIVATE_SUCCESS
      })
    case actions.INIT_VERIFIED_SUCCESS_PAGE:
      return Object.assign({}, state, {
        facet: Enums.FACETS.VERIFY_SUCCESS
      })
    case actions.INIT_VERIFIED_FAILED_PAGE:
      return Object.assign({}, state, {
        facet: Enums.FACETS.VERIFY_FAILED
      })
    case actions.INIT_VERIFIED_CONFIRM_PAGE:
      return Object.assign({}, state, {
        facet: Enums.FACETS.VERIFY_CONFIRM,
        user: {
          loggedIn: false,
          signInClicked: false
        }
      })
    case actions.INIT_HOME_PAGE:
      MemoryStore.registerForm = {}

      return Object.assign({}, state, {
        facet: Enums.FACETS.SIGN_IN
      })
    case actions.UPDATE_TABS:
      tabArray = state.tabObject.tabs.concat()
      index = ''

      // If tab exists, activate it
      for (const i in tabArray) {
        if (tabArray[i].key === action.payload.key) {
          return Object.assign({}, state, {
            tabObject: {
              tabs: tabArray,
              activeKey: action.payload.key,
              activeApp: action.payload.app,
              tabType: action.payload.tabType
            }
          })
        }
      }

      // If we get here, we need to add a new Tab
      // See if a tab with the same app exists and find its index
      index = tabArray.findIndex((tab) => {
        return tab.app === action.payload.app
      })

      // Increment the index to add tab immediately after tab with same app
      index++

      // if index === 0 then we push the tab to array
      // because then no tab with the same app was found
      // otherwise we add that tab immediately after the tab with same app
      if (index === 0) {
        tabArray.push(action.payload)
      } else {
        tabArray.splice(index, 0, action.payload)
      }

      return Object.assign({}, state, {
        tabObject: {
          tabs: tabArray,
          activeKey: action.payload.key,
          activeApp: action.payload.app,
          tabType: action.payload.tabType
        }
      })
    case actions.CHANGE_TAB:
      return Object.assign({}, state, {
        tabObject: {
          ...state.tabObject,
          activeKey: action.key,
          activeApp: action.app,
          tabType: action.tabType
        }
      })
    case actions.CLOSE_TAB:
      return Object.assign({}, state, {
        tabObject: {
          tabs: action.tabs,
          activeKey: action.activeKey,
          activeApp: action.activeApp,
          tabType: action.tabType
        }
      })
    case actions.RESET_TABS:
      return Object.assign({}, state, {
        app: Enums.APP_IDS.HOME,
        tabObject: {
          tabs: [],
          activeKey: Enums.APP_IDS.HOME,
          activeApp: Enums.VALUES_STRINGS.EMPTY,
          tabType: Enums.VALUES_STRINGS.EMPTY
        }
      })
    default:
      return state
  }
}
