import React from 'react'

import { Row, Col, Form, Input, Switch } from 'antd'

import MemoryStore from '../../../../../utils/memory-store'
import AceEditor from 'react-ace'
import 'brace/mode/json'
import 'brace/theme/xcode'

class ConnectorsFormTypeGoogleDrive extends React.Component {
  constructor(props) {
    super(props)

    this.entry = MemoryStore.activeEntries[this.props.tabKey]

    this.state = {
      entry: props.entry
    }

    this.onFieldChange = this.onFieldChange.bind(this)
  }

  onFieldChange(key, value) {
    this.entry.custom.isModified = true
    this.props.entry[key] = value
  }

  render() {
    return (
      <Row>
        <Col xs={24} lg={16}>
          <Form.Item>
            <span style={{ color: 'red' }}>* </span>
            Key File Path & Name
            <Input
              name='key_file_path'
              placeholder='e.g. /app/gdrive-config/service-account.json'
              disabled={MemoryStore.userProfile.teamPrivileges.connectors === 'Reader'}
              defaultValue={this.state.entry.keyFilePath}
              onChange={(e) => {
                this.onFieldChange('keyFilePath', e.target.value)
              }}
            />
          </Form.Item>
          <Form.Item>
            Is File Encrypted?
            <Switch
              style={{ marginLeft: 10 }}
              defaultChecked={this.state.entry.isFileEncrypted !== '0'}
              checkedChildren='Yes'
              unCheckedChildren='No'
              onChange={(e) => {
                this.onFieldChange('isFileEncrypted', e === false ? '0' : '1')
              }}
            />
          </Form.Item>
        </Col>
      </Row>
    )
  }
}

export default ConnectorsFormTypeGoogleDrive
